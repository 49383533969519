import React, { useState, useMemo } from "react"
import { graphql, Link } from "gatsby"
import Layout from "@/components/Layout"
import { cn, isBrowser } from "@/utils/helpers"
import { Product, useCartStore } from "@/utils/store/store"
import Container from "@/components/reusable/Container"
import { BsPlus, BsDash } from "react-icons/bs"
import { TbExternalLink } from "react-icons/tb"
import { HiOutlineTrash, HiX } from "react-icons/hi"
import { convertToCurrency } from "@/components/ProductPage/Price"
import { ButtonLink } from "@/components/reusable/Button"
import { CHECKOUT_URL, CONFIRMATION_URL } from "@/lib/constants"
import Modal from "@/components/reusable/Modal"
import Wrapper from "@/components/reusable/Wrapper"
import parse from "html-react-parser"

const BasketPage = ({ data }: { data: any }) => {
  const products = data.allWpProduct.edges
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [itemToRemove, setItemToRemove] = useState<Product | null>(null)
  const cart = useCartStore((state) => state.cart)
  const removeItem = useCartStore((state) => state.removeItem)
  const incrementItemQuantity = useCartStore(
    (state) => state.incrementItemQuantity
  )
  const decrementItemQuantity = useCartStore(
    (state) => state.decrementItemQuantity
  )

  if (!isBrowser) return null

  const incrementButtonStyles = `p-2 bg-gray-200/40 rounded-md transition hover:bg-gray-300`

  function handleDecrement(item: Product) {
    if (item.quantity === 1) return
    decrementItemQuantity(item)
  }

  function handleIncrement(item: Product) {
    // TODO: Check if item is in stock before incrementing
    // TODO: Add a toast to show that the item is out of stock
    // TODO: Add max quantity
    incrementItemQuantity(item)
  }

  function handleRemoveItem(item: Product) {
    setIsModalOpen(true)
    // pass removeItem function to modal
    setItemToRemove(item)
  }

  function getCartTotal() {
    const total = cart.reduce((total: number, item: any) => {
      const product = products.find((product: any) =>
        product.node.ProductData.variants.find(
          (variant: any) => variant.variantId === item.id
        )
      )

      if (!product) return total

      const variant = product.node.ProductData.variants.find(
        (variant: any) => variant.variantId === item.id
      )

      const currentPrice = variant?.price * item.quantity

      return total + currentPrice
    }, 0)

    return convertToCurrency(total)
  }

  function generateCheckoutUrl() {
    if (CHECKOUT_URL === "") return ""
    const checkoutUrl = cart.reduce((url: string, item: any) => {
      const product = products.find((product: any) =>
        product.node.ProductData.variants.find(
          (variant: any) => variant.variantId === item.id
        )
      )

      if (!product) return url

      const variant = product.node.ProductData.variants.find(
        (variant: any) => variant.variantId === item.id
      )

      const currentPrice = variant?.price * item.quantity

      return url + `${variant?.variantId}:${item.quantity},`
    }, CHECKOUT_URL)

    return `${checkoutUrl}?return_to=${CONFIRMATION_URL}`
  }

  const productToRemove = useMemo(
    () =>
      itemToRemove &&
      products.find((product: any) =>
        product.node.ProductData.variants.find(
          (variant: any) => variant.variantId === itemToRemove.id
        )
      ),
    [itemToRemove, products]
  )

  return (
    <Layout
      meta={{
        metaTitle: "Your Basket",
        metaDescription:
          "Items in your basket – Your shopping basket – Proceed to check out to complete your order",
      }}
      path="/customer/basket"
    >
      {itemToRemove && (
        <Modal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          title="Remove item"
          description={`Are you sure you want to remove ${productToRemove.node.title} from your basket?`}
          confirmText="Remove"
          onConfirm={() => {
            removeItem(itemToRemove)
            setIsModalOpen(false)
            setItemToRemove(null)
          }}
        />
      )}
      <div
        className={`basket-page-title mx-auto my-0 mb-4 mb-10 bg-theme-primary pt-12 pb-9 md:mb-16 md:pt-20 md:pb-16 [&_p]:mb-4 [&_p]:md:mr-1/6 [&_p]:md:max-w-5/6 [&_.gatsby-image-wrapper]:mx-3 [&_.gatsby-image-wrapper]:mb-4 [&_.gatsby-image-wrapper]:md:mt-12 [&_p.text-center]:md:mx-1/12`}
      >
        <Wrapper>
          <h1
            className={`relative mx-auto mb-2 block w-full max-w-wrapper px-3 font-header text-5xl font-bold leading-[1.16] text-theme-primary-contrast lg:text-[68px] lg:leading-[1.05]`}
          >
            Basket
          </h1>
        </Wrapper>
      </div>
      <Container>
        {cart.length > 0 ? (
          <div className="my-10 mx-auto max-w-3xl space-y-8">
            {cart.map((item: any, index: number) => {
              const product = products.find((product: any) =>
                product.node.ProductData.variants.find(
                  (variant: any) => variant.variantId === item.id
                )
              )

              if (!product) return null

              const variant = product.node.ProductData.variants.find(
                (variant: any) => variant.variantId === item.id
              )

              const currentPrice = variant?.price * item.quantity

              const variantImage = product.node.ProductData.images.find(
                (image: { image: any }) => image.image === variant?.image
              )

              return (
                <div
                  key={item.id}
                  className={cn(
                    `flex`,
                    index !== cart.length - 1
                      ? `border-b border-b-black/25 pb-8`
                      : null
                  )}
                >
                  <Link
                    to={`${product?.node.uri}`}
                    className="group relative mr-8 before:absolute before:inset-0 before:block before:rounded-md before:bg-black/20 before:opacity-0 before:transition hover:before:opacity-100"
                  >
                    <div className="absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2">
                      <TbExternalLink className=" h-10 w-10 translate-y-1 text-white opacity-0 transition group-hover:translate-y-0 group-hover:opacity-100" />
                    </div>
                    <img
                      src={
                        variantImage?.image ||
                        product?.node.ProductData.featuredImage
                      }
                      alt={product?.node.title}
                      className={cn(
                        `block aspect-square w-32`,
                        `rounded-md object-cover object-center`
                      )}
                    />
                  </Link>
                  <div className="relative flex flex-1 flex-col">
                    <div className="mb-6">
                      <h2 className="text-xl font-bold">
                        {product?.node.title}
                      </h2>
                      {variant?.title && (
                        <div className="flex items-center gap-3 text-sm font-medium uppercase opacity-50">
                          <p>{parse(variant?.title)}</p>
                        </div>
                      )}
                    </div>
                    <div className="mt-auto flex items-end justify-between">
                      <div className="">
                        {item.quantity > 1 ? (
                          <p className="flex items-center gap-1 text-sm opacity-50">
                            {convertToCurrency(variant.price)} <HiX />{" "}
                            {item.quantity}
                          </p>
                        ) : null}
                        <p className="text-lg">
                          {convertToCurrency(currentPrice)}
                        </p>
                      </div>

                      <button
                        className={cn(
                          incrementButtonStyles,
                          `absolute top-0 right-0`
                        )}
                        onClick={() => handleRemoveItem(item)}
                      >
                        <HiOutlineTrash className="h-5 w-5" />
                      </button>

                      <div className="flex items-center gap-4">
                        <button
                          className={cn(incrementButtonStyles)}
                          onClick={() => handleDecrement(item)}
                        >
                          <BsDash className="h-5 w-5" />
                        </button>
                        <p>{item.quantity}</p>
                        <button
                          className={cn(incrementButtonStyles)}
                          onClick={() => handleIncrement(item)}
                        >
                          <BsPlus className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div className="mb-10 flex items-center justify-center py-10">
            <div className="flex flex-col items-center">
              <p className="mb-4 font-header text-2xl">Your basket is empty</p>
              {/* link to shop */}
              <ButtonLink link="/shop">Shop Now</ButtonLink>
            </div>
          </div>
        )}

        {cart.length > 0 ? (
          <div className="mx-auto mb-10 max-w-3xl text-right md:mb-20">
            <h3 className="mb-6 text-xl font-bold">Total: {getCartTotal()}</h3>
            {/* <Button onClick={handleCheckout}>Checkout</Button> */}
            {CHECKOUT_URL === "" ? (
              `Please add Shopify Storefront API URL`
            ) : (
              <ButtonLink link={generateCheckoutUrl()}>Checkout now</ButtonLink>
            )}
          </div>
        ) : null}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpProduct {
      edges {
        node {
          id
          title
          uri
          ProductData {
            comparePrice
            description
            featuredImage
            options
            price
            images {
              image
            }
            title
            productIdBase64
            variants {
              inStock
              comparePrice
              image
              option1
              option2
              option3
              price
              quantity
              sku
              title
              weight
              variantId
            }
          }
        }
      }
    }
  }
`

export default BasketPage
